import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import sanitizeHtml from 'sanitize-html'
import {
  Box,
  Container,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import AboutData from 'content/about.json'
import Layout from 'components/Layout'
import Icons from 'components/Icons'
import { getImage } from 'helpers/getImage'

type AboutPageParams = {
  data: {
    images: any
  }
}

const AboutPage: FC<AboutPageParams> = ({ data }) => {
  const { t } = useTranslation()
  const { list } = AboutData
  const { images } = data

  return (
    <Layout>
      <Box bg="black.600" color="white">
        <Container mt={{ base: '4.25rem', lg: '5rem' }}>
          <Box py={{ base: '4rem', md: '6rem' }} textAlign="center">
            <Text color="primary" fontWeight="600" mb="3">
              {t('about.subHeading')}
            </Text>
            <Heading as="h1" size="lg" mb="1.5rem">
              {t('about.title')}
            </Heading>
            <Text
              size="xl"
              maxW={['100%', '768px']}
              mx="auto"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(t('about.subtitle')),
              }}
            />
          </Box>
        </Container>
      </Box>
      <Container my={['4rem', '6rem']}>
        <Box mb="4rem" textAlign="center">
          <Heading>{t('about.team.title')}</Heading>
          <Text
            size="xl"
            mt="1.25rem"
            color="gray.400"
            maxW={['100%', '768px']}
            mx="auto"
          >
            {t('about.team.description')}
          </Text>
        </Box>
        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          spacing={['2.5rem', '4rem 2rem']}
          maxW={{ base: '100%', lg: '90%', xl: '80%' }}
          m="0 auto"
          justifyContent="center"
          textAlign="center"
        >
          {list.map((member, idx) => (
            <Box key={idx}>
              <Box mb="1.5rem" width="5rem" height="5rem" mx="auto">
                <GatsbyImage
                  alt={member.name}
                  image={getImage(images.nodes, member.name)}
                  style={{ borderRadius: '1.25rem' }}
                />
              </Box>
              <Text size="xl" fontWeight="500">
                {t(`about.team.members.${member.name}.name`)}
              </Text>
              <Text size="lg" mt="1" color="blue.400">
                {t(`about.team.members.${member.name}.job`)}
              </Text>
              <Flex mt={['1rem', '1.5rem']} justifyContent="center">
                <Link href={member.linkedin} isExternal>
                  <Icons name="linkedin" />
                </Link>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allFile(filter: { relativeDirectory: { eq: "about" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        name
      }
    }
  }
`

export default AboutPage
